import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthHandler = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [user, token, navigate]);

  return null;
};

export default AuthHandler;
