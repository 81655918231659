import React, { useState } from "react";
import { Menu, Dropdown, Space, Drawer, Button } from "antd";
import { Link } from "react-router-dom";
import {
  DownOutlined,
  CalculatorOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import ProfileMenu from "./ProfileMenu";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Person as UserOutlined,
  Group as TeamOutlined,
  Warehouse as WarehouseOutlined,
} from "@mui/icons-material";
import styled from "styled-components";

const rateInquiryMenu = (
  <Menu>
    <Menu.Item key="us-rate-inquiry">
      <Link to="/usr/us-rate-inquiry">US Rate Inquiry</Link>
    </Menu.Item>
  </Menu>
);

const usersMenu = (
  <Menu>
    <Menu.Item key="user1">
      <Link to="/user1">All Users</Link>
    </Menu.Item>
    <Menu.Item key="user2">
      <Link to="/user2">Create User</Link>
    </Menu.Item>
  </Menu>
);

const clientsMenu = (
  <Menu>
    <Menu.Item key="clientList">
      <Link to="/usr/clientList">Client List</Link>
    </Menu.Item>
  </Menu>
);

const warehouseMenu = (
  <Menu>
    <Menu.Item key="warehouses">
      <Link to="/usr/warehouseList">Warehouses</Link>
    </Menu.Item>
    <Menu.Item key="warehouseProducts">
      <Link to="/usr/warehouseProductList">Warehouse Products</Link>
    </Menu.Item>
  </Menu>
);

const listPageMenu = (
  <Menu>
    <Menu.Item key="orders">
      <Link to="/usr/orderList">Orders</Link>
    </Menu.Item>
    <Menu.Item key="shipments">
      <Link to="/usr/shipmentList">Shipments</Link>
    </Menu.Item>
  </Menu>
);

const NavBar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <StyledNavBar>
      <div className="left">
        <Button type="text" onClick={showDrawer} className="menuButton">
          <MenuOutlined />
        </Button>
        <Drawer
          title="Navigation"
          placement="left"
          closable
          onClose={closeDrawer}
          open={drawerVisible}
        >
          <div className="drawerContent">
            <Link
              to="/usr/dashboard"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Dashboard</span>
            </Link>
            <br />
            <Link
              to="/usr/us-rate-inquiry"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>US Rate Inquiry</span>
            </Link>
            <br />
            <Link to="/user1" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>All Users</span>
            </Link>
            <br />
            <Link to="/user2" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Create User</span>
            </Link>
            <br />
            <Link to="/client1" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Client 1</span>
            </Link>
            <br />
            <Link to="/client2" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Client 2</span>
            </Link>
            <br />
            <Link to="/warehouse1" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>All Warehouses</span>
            </Link>
            <br />
            <Link to="/warehouse2" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Create Warehouse</span>
            </Link>
            <br />

            <Link to="/orders" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Orders</span>
            </Link>
            <br />
            <Link to="/shipments" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Shipments</span>
            </Link>

          </div>
        </Drawer>
      </div>
      <div className="center">
        <a href="/usr/dashboard" className="dropdown">
          <Space>
            <TrendingUpIcon style={{ color: "#E8993C" }} />
            <span style={{ verticalAlign: "middle" }}>Dashboard</span>
          </Space>
        </a>
        <Dropdown overlay={rateInquiryMenu} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <CalculatorOutlined style={{ color: "#963CE8" }} />
              <span style={{ verticalAlign: "middle" }}>Rate Inquiry</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown overlay={usersMenu} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <UserOutlined className="icon" />
              <span style={{ verticalAlign: "middle" }}>Users</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown overlay={clientsMenu} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <TeamOutlined style={{ color: "green" }} />
              <span style={{ verticalAlign: "middle" }}>Clients</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown overlay={warehouseMenu} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <WarehouseOutlined style={{ color: "#55A4ED" }} />
              <span style={{ verticalAlign: "middle" }}>Warehouse</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>

        <Dropdown overlay={listPageMenu} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <RocketLaunchIcon style={{ color: "#D34339" }} />
              <span style={{ verticalAlign: "middle" }}>Lists</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className="right">
        <ProfileMenu />
      </div>
    </StyledNavBar>
  );
};

const StyledNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #f8f8f8;
  color: black;
  height: 90px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 1.1em;
  position: relative;
  z-index: 10; /* Ensure navbar is above other content */

  .left {
    display: none;
    flex: 1;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
    flex: 3;
    gap: 30px;
  }

  .right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .dropdown {
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
  }

  .dropdownicon {
    font-size: 0.6em;
  }

  .icon {
    color: #3bc3b3;
  }

  .link {
    margin: 0 30px;
    white-space: nowrap;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
  }

  .line {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #3bc3b3, #e6e628, #3bc3b3);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  .menuButton {
    font-size: 1.5em;
    color: black;
  }

  .drawerContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0; /* Add padding to top and bottom */
  }

  .drawerLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    padding: 10px 0;
    gap: 10px; /* Add gap between icon and text */
  }

  .drawerIcon {
    margin-right: 10px; /* Add margin to the right of icons */
  }

  @media (max-width: 768px) {
    .center {
      display: none;
    }
    .left {
      display: flex;
    }
  }

  @media (min-width: 769px) {
    .center {
      display: flex;
    }
    .left {
      display: none;
    }
  }
`;

export default NavBar;
