import { notification } from 'antd';
import ErrorOutlined from "@mui/icons-material/ErrorOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export const notify = (type, message) => {
  if (type === 'error') {
    notification.open({
      message: message,
      icon: <ErrorOutlined style={{ color: 'red' }} />,
      placement: 'top',
      duration: 2,
    });
  } else {
    notification.open({
      message: message,
      icon: <InfoOutlined style={{ color: 'green' }} />,
      placement: 'top',
      duration: 2,
    });
  }
};
