import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import Login from "./Pages/Authentication/Login.js";
import USRateInquiry from "./Pages/rateInquiry/usRateInquiry.js";
import USRateInquiryResult from './Pages/rateInquiry/rateInquiryResult.js';
import AuthHandler from "./components/authHandler.js";
import OrderList from './Pages/OrderList/orderList';
import OrderDetail from './Pages/OrderDetail/orderDetail';
import ShipmentList from './Pages/ShipmentList/shipmentList';
import ShipmentDetail from './Pages/ShipmentDetail/shipmentDetail';
import WarehouseList from "./Pages/WarehouseList/warehouseList.js";
import WarehouseProductList from './Pages/WarehouseProduct/warehouseProductList.js';
import WarehouseProductDetail from './Pages/WarehouseProduct/warehouseProductDetail.js';
import ClientList from './Pages/Client/clientList.js';
import ClientDetail from './Pages/Client/clientDetail.js';

const App = () => {
  return (
    <div className="App">
      <Router>
        <ConditionalAuthHandler />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/usr/dashboard" element={<Dashboard />} />
          <Route path="/usr/us-rate-inquiry" element={<USRateInquiry />} />
          <Route path="/usr/result" element={<USRateInquiryResult />} />
          {/* Add other routes here */}
          <Route path="/usr/orderList" element={<OrderList />} />
          <Route path="/usr/orderDetail/:orderId" element={<OrderDetail />} />
          <Route path="/usr/shipmentList" element={<ShipmentList />} />
          <Route path="/usr/shipmentDetail/:shipmentId" element={<ShipmentDetail />} />
          <Route path="/usr/warehouseList" element={<WarehouseList />} />
          <Route path="/usr/warehouseProductList" element={<WarehouseProductList />} />
          <Route path="/usr/warehouseProductDetail/:productId" element={<WarehouseProductDetail />} />
          <Route path="/usr/clientList" element={<ClientList />} />
          <Route path="/usr/clientDetail/:clientId" element={<ClientDetail />} />
          
          {/* Add other routes here */}
        </Routes>
      </Router>
    </div>
  );
};

const ConditionalAuthHandler = () => {
  const location = useLocation();
  const excludePaths = ["/"]; // Add paths you want to exclude from AuthHandler

  if (excludePaths.includes(location.pathname)) {
    return null;
  }

  return <AuthHandler />;
};

export default App;
