// src/features/auth/authSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { signinRequest, signinSuccess, signinError } from "./authSlice";
import { notify } from "../../utils/notifications"; // Assuming you have a notification function
import { history } from "../../utils/history"; // Assuming you have history setup for navigation
import { apiClient } from "../../api";

function* signin(action) {
  try {
    const response = yield call(
      apiClient.post,
      "/auth/signin/",
      action.payload
    );
    const token = response.data.access_token;
    if (token) {
      localStorage.setItem("token", token);
      yield put(signinSuccess(response.data));
      window.location.href = '/usr/dashboard';  // navigate to dashboard
      notify("success", "Log in successful");
    } else {
      yield put(signinError("Invalid credentials"));
      notify("error", "Invalid credentials");
    }
  } catch (error) {
    yield put(signinError(error.message));
    notify("error", error.message);
  }
}

function* watchSignin() {
  yield takeLatest(signinRequest.type, signin);
}

export default watchSignin;
