import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Dropdown, Avatar } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const ProfileMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="settings">
        <SettingOutlined style={{ fontSize: "18px", color: "silver" }} />
        <span style={{ fontSize: "15px" }}> Settings</span>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined style={{ fontSize: "18px", color: "#D34339" }} />
        <span style={{ fontSize: "15px" }}> Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={profileMenu} trigger={["click", "hover"]}>
      <a
        href="/"
        onClick={(e) => e.preventDefault()}
        style={styles.profileLink}
      >
        <Avatar
          style={{ width: "40px", height: "40px" }}
          icon={<UserOutlined style={{ fontSize: "25px" }} />}
        />
      </a>
    </Dropdown>
  );
};

const styles = {
  profileLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  avatar: {
    backgroundColor: "#87d068",
  },
};

export default ProfileMenu;
